<mat-form-field [style]="getStyle()" [appearance]="getAppearance()">
  <mat-label>{{config().getLabel()}}</mat-label>
  <mat-select
    [(ngModel)]="selectedOptions"
    [disabled]="config().getDisabled()!"
    [disableRipple]="config().getDisableRipple()"
    [tabIndex]="config().getTabIndex()"
    [placeholder]="config().getPlaceholder()!"
    (openedChange)="onSelectOpenedChangeEvent($event)"
    (selectionChange)="onSelectSelectionChangeEvent($event)"
    [multiple]="config().getMultiple()"
  >
    @for(option of config().getOptionsList(); track $index) {
    <mat-option [value]="option.getValue()">{{option.getLabel()}}</mat-option>
    }
  </mat-select>
</mat-form-field>
