// source: mesop/components/uploader/uploader.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.mesop.components.uploader.UploadEvent', null, global);
goog.exportSymbol('proto.mesop.components.uploader.UploadedFile', null, global);
goog.exportSymbol('proto.mesop.components.uploader.UploaderType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.uploader.UploadedFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.components.uploader.UploadedFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.uploader.UploadedFile.displayName = 'proto.mesop.components.uploader.UploadedFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.uploader.UploadEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.components.uploader.UploadEvent.repeatedFields_, null);
};
goog.inherits(proto.mesop.components.uploader.UploadEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.uploader.UploadEvent.displayName = 'proto.mesop.components.uploader.UploadEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.uploader.UploaderType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.components.uploader.UploaderType.repeatedFields_, null);
};
goog.inherits(proto.mesop.components.uploader.UploaderType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.uploader.UploaderType.displayName = 'proto.mesop.components.uploader.UploaderType';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.uploader.UploadedFile.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.uploader.UploadedFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.uploader.UploadedFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.uploader.UploadedFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    size: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    mimeType: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    contents: msg.getContents_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.uploader.UploadedFile}
 */
proto.mesop.components.uploader.UploadedFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.uploader.UploadedFile;
  return proto.mesop.components.uploader.UploadedFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.uploader.UploadedFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.uploader.UploadedFile}
 */
proto.mesop.components.uploader.UploadedFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMimeType(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.uploader.UploadedFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.uploader.UploadedFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.uploader.UploadedFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.uploader.UploadedFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mesop.components.uploader.UploadedFile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.uploader.UploadedFile} returns this
 */
proto.mesop.components.uploader.UploadedFile.prototype.setName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.uploader.UploadedFile} returns this
 */
proto.mesop.components.uploader.UploadedFile.prototype.clearName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.uploader.UploadedFile.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 size = 2;
 * @return {number}
 */
proto.mesop.components.uploader.UploadedFile.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.components.uploader.UploadedFile} returns this
 */
proto.mesop.components.uploader.UploadedFile.prototype.setSize = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.uploader.UploadedFile} returns this
 */
proto.mesop.components.uploader.UploadedFile.prototype.clearSize = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.uploader.UploadedFile.prototype.hasSize = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string mime_type = 3;
 * @return {string}
 */
proto.mesop.components.uploader.UploadedFile.prototype.getMimeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.uploader.UploadedFile} returns this
 */
proto.mesop.components.uploader.UploadedFile.prototype.setMimeType = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.uploader.UploadedFile} returns this
 */
proto.mesop.components.uploader.UploadedFile.prototype.clearMimeType = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.uploader.UploadedFile.prototype.hasMimeType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes contents = 4;
 * @return {!(string|Uint8Array)}
 */
proto.mesop.components.uploader.UploadedFile.prototype.getContents = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes contents = 4;
 * This is a type-conversion wrapper around `getContents()`
 * @return {string}
 */
proto.mesop.components.uploader.UploadedFile.prototype.getContents_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContents()));
};


/**
 * optional bytes contents = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContents()`
 * @return {!Uint8Array}
 */
proto.mesop.components.uploader.UploadedFile.prototype.getContents_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContents()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.mesop.components.uploader.UploadedFile} returns this
 */
proto.mesop.components.uploader.UploadedFile.prototype.setContents = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.uploader.UploadedFile} returns this
 */
proto.mesop.components.uploader.UploadedFile.prototype.clearContents = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.uploader.UploadedFile.prototype.hasContents = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.components.uploader.UploadEvent.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.uploader.UploadEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.uploader.UploadEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.uploader.UploadEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.uploader.UploadEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileList: jspb.Message.toObjectList(msg.getFileList(),
    proto.mesop.components.uploader.UploadedFile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.uploader.UploadEvent}
 */
proto.mesop.components.uploader.UploadEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.uploader.UploadEvent;
  return proto.mesop.components.uploader.UploadEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.uploader.UploadEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.uploader.UploadEvent}
 */
proto.mesop.components.uploader.UploadEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.components.uploader.UploadedFile;
      reader.readMessage(value,proto.mesop.components.uploader.UploadedFile.deserializeBinaryFromReader);
      msg.addFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.uploader.UploadEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.uploader.UploadEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.uploader.UploadEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.uploader.UploadEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mesop.components.uploader.UploadedFile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UploadedFile file = 1;
 * @return {!Array<!proto.mesop.components.uploader.UploadedFile>}
 */
proto.mesop.components.uploader.UploadEvent.prototype.getFileList = function() {
  return /** @type{!Array<!proto.mesop.components.uploader.UploadedFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.components.uploader.UploadedFile, 1));
};


/**
 * @param {!Array<!proto.mesop.components.uploader.UploadedFile>} value
 * @return {!proto.mesop.components.uploader.UploadEvent} returns this
*/
proto.mesop.components.uploader.UploadEvent.prototype.setFileList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mesop.components.uploader.UploadedFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.components.uploader.UploadedFile}
 */
proto.mesop.components.uploader.UploadEvent.prototype.addFile = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mesop.components.uploader.UploadedFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.components.uploader.UploadEvent} returns this
 */
proto.mesop.components.uploader.UploadEvent.prototype.clearFileList = function() {
  return this.setFileList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.components.uploader.UploaderType.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.uploader.UploaderType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.uploader.UploaderType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.uploader.UploaderType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.uploader.UploaderType.toObject = function(includeInstance, msg) {
  var f, obj = {
    acceptedFileTypeList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    onUploadEventHandlerId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    color: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    disableRipple: (f = jspb.Message.getBooleanField(msg, 4)) == null ? undefined : f,
    disabled: (f = jspb.Message.getBooleanField(msg, 5)) == null ? undefined : f,
    typeIndex: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    type: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.uploader.UploaderType}
 */
proto.mesop.components.uploader.UploaderType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.uploader.UploaderType;
  return proto.mesop.components.uploader.UploaderType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.uploader.UploaderType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.uploader.UploaderType}
 */
proto.mesop.components.uploader.UploaderType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAcceptedFileType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnUploadEventHandlerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableRipple(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTypeIndex(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.uploader.UploaderType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.uploader.UploaderType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.uploader.UploaderType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.uploader.UploaderType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAcceptedFileTypeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * repeated string accepted_file_type = 1;
 * @return {!Array<string>}
 */
proto.mesop.components.uploader.UploaderType.prototype.getAcceptedFileTypeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.setAcceptedFileTypeList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.addAcceptedFileType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.clearAcceptedFileTypeList = function() {
  return this.setAcceptedFileTypeList([]);
};


/**
 * optional string on_upload_event_handler_id = 2;
 * @return {string}
 */
proto.mesop.components.uploader.UploaderType.prototype.getOnUploadEventHandlerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.setOnUploadEventHandlerId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.clearOnUploadEventHandlerId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.uploader.UploaderType.prototype.hasOnUploadEventHandlerId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string color = 3;
 * @return {string}
 */
proto.mesop.components.uploader.UploaderType.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.setColor = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.clearColor = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.uploader.UploaderType.prototype.hasColor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool disable_ripple = 4;
 * @return {boolean}
 */
proto.mesop.components.uploader.UploaderType.prototype.getDisableRipple = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.setDisableRipple = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.clearDisableRipple = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.uploader.UploaderType.prototype.hasDisableRipple = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool disabled = 5;
 * @return {boolean}
 */
proto.mesop.components.uploader.UploaderType.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.setDisabled = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.clearDisabled = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.uploader.UploaderType.prototype.hasDisabled = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 type_index = 6;
 * @return {number}
 */
proto.mesop.components.uploader.UploaderType.prototype.getTypeIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.setTypeIndex = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.clearTypeIndex = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.uploader.UploaderType.prototype.hasTypeIndex = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.mesop.components.uploader.UploaderType.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.setType = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.uploader.UploaderType} returns this
 */
proto.mesop.components.uploader.UploaderType.prototype.clearType = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.uploader.UploaderType.prototype.hasType = function() {
  return jspb.Message.getField(this, 7) != null;
};


goog.object.extend(exports, proto.mesop.components.uploader);
