// source: mesop/components/date_range_picker/date_range_picker.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.mesop.components.date_range_picker.DateRangePickerType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.date_range_picker.DateRangePickerType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.components.date_range_picker.DateRangePickerType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.date_range_picker.DateRangePickerType.displayName = 'proto.mesop.components.date_range_picker.DateRangePickerType';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.date_range_picker.DateRangePickerType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.date_range_picker.DateRangePickerType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.date_range_picker.DateRangePickerType.toObject = function(includeInstance, msg) {
  var f, obj = {
    startDate: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    endDate: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    placeholderStartDate: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    placeholderEndDate: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    disabled: (f = jspb.Message.getBooleanField(msg, 5)) == null ? undefined : f,
    required: (f = jspb.Message.getBooleanField(msg, 6)) == null ? undefined : f,
    readonly: (f = jspb.Message.getBooleanField(msg, 7)) == null ? undefined : f,
    hideRequiredMarker: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f,
    color: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    floatLabel: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    appearance: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
    subscriptSizing: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
    hintLabel: (f = jspb.Message.getField(msg, 13)) == null ? undefined : f,
    label: (f = jspb.Message.getField(msg, 14)) == null ? undefined : f,
    onChangeHandlerId: (f = jspb.Message.getField(msg, 15)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.date_range_picker.DateRangePickerType;
  return proto.mesop.components.date_range_picker.DateRangePickerType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.date_range_picker.DateRangePickerType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceholderStartDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceholderEndDate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadonly(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideRequiredMarker(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFloatLabel(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppearance(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptSizing(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setHintLabel(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnChangeHandlerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.date_range_picker.DateRangePickerType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.date_range_picker.DateRangePickerType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.date_range_picker.DateRangePickerType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string start_date = 1;
 * @return {string}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setStartDate = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearStartDate = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string end_date = 2;
 * @return {string}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setEndDate = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearEndDate = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string placeholder_start_date = 3;
 * @return {string}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getPlaceholderStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setPlaceholderStartDate = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearPlaceholderStartDate = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasPlaceholderStartDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string placeholder_end_date = 4;
 * @return {string}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getPlaceholderEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setPlaceholderEndDate = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearPlaceholderEndDate = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasPlaceholderEndDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool disabled = 5;
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setDisabled = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearDisabled = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasDisabled = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool required = 6;
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setRequired = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearRequired = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasRequired = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool readonly = 7;
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getReadonly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setReadonly = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearReadonly = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasReadonly = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool hide_required_marker = 8;
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getHideRequiredMarker = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setHideRequiredMarker = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearHideRequiredMarker = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasHideRequiredMarker = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string color = 9;
 * @return {string}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setColor = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearColor = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasColor = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string float_label = 10;
 * @return {string}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getFloatLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setFloatLabel = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearFloatLabel = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasFloatLabel = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string appearance = 11;
 * @return {string}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getAppearance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setAppearance = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearAppearance = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasAppearance = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string subscript_sizing = 12;
 * @return {string}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getSubscriptSizing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setSubscriptSizing = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearSubscriptSizing = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasSubscriptSizing = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string hint_label = 13;
 * @return {string}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getHintLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setHintLabel = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearHintLabel = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasHintLabel = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string label = 14;
 * @return {string}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setLabel = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearLabel = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string on_change_handler_id = 15;
 * @return {string}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.getOnChangeHandlerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.setOnChangeHandlerId = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.date_range_picker.DateRangePickerType} returns this
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.clearOnChangeHandlerId = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.date_range_picker.DateRangePickerType.prototype.hasOnChangeHandlerId = function() {
  return jspb.Message.getField(this, 15) != null;
};


goog.object.extend(exports, proto.mesop.components.date_range_picker);
