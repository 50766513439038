<input
  type="file"
  accept="{{ accept() }}"
  (change)="onFileSelected($event)"
  #fileUpload
/>
<div>
  @if(config().getTypeIndex() === 0) {
  <button
    mat-button
    [color]="config().getColor()"
    [disableRipple]="config().getDisableRipple()"
    [disabled]="config().getDisabled()"
    [style]="getStyle()"
    (click)="fileUpload.click()"
  >
    <ng-content></ng-content>
  </button>

  } @if(config().getTypeIndex() === 1) {
  <button
    mat-raised-button
    [color]="config().getColor()"
    [disableRipple]="config().getDisableRipple()"
    [disabled]="config().getDisabled()"
    [style]="getStyle()"
    (click)="fileUpload.click()"
  >
    <ng-content></ng-content>
  </button>

  } @if(config().getTypeIndex() === 2) {
  <button
    mat-flat-button
    [color]="config().getColor()"
    [disableRipple]="config().getDisableRipple()"
    [disabled]="config().getDisabled()"
    [style]="getStyle()"
    (click)="fileUpload.click()"
  >
    <ng-content></ng-content>
  </button>

  } @if(config().getTypeIndex() === 3) {
  <button
    mat-stroked-button
    [color]="config().getColor()"
    [disableRipple]="config().getDisableRipple()"
    [disabled]="config().getDisabled()"
    [style]="getStyle()"
    (click)="fileUpload.click()"
  >
    <ng-content></ng-content>
  </button>

  } @if(config().getTypeIndex() === 4) {
  <button
    mat-icon-button
    [color]="config().getColor()"
    [disableRipple]="config().getDisableRipple()"
    [disabled]="config().getDisabled()"
    [style]="getStyle()"
    (click)="fileUpload.click()"
  >
    <ng-content></ng-content>
  </button>

  }
</div>
